.clientContainer {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 30px;
  padding: 30px 30px 0 30px;
  overflow-y: scroll;
  height: 100%;
}

.ImageContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: lighten;
}

.ImageContainer > img {
  width: 100%;
  height: auto;
}

@media only screen and (min-width: 768px) {
  .clientContainer {
    grid-template-columns: auto auto auto auto auto;
    grid-gap: 50px;
    padding: 50px 50px 0 50px;
  }
}