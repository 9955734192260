@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.aboutContainer {
  height: 100%;
  overflow-y: scroll;
}

.socialMediaButtonGroup {
  display: flex;
  justify-content: center;
  animation: 1s ease-out 0s 1 slideInFromLeft;
}

.socialMediaButton {
  /* border: 2px solid rgb(75, 211, 209); */
  /* border-radius: 5px; */
  width: 90%;
  margin-left: 5%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3%;
  font-family: cursive;
  color: #9c0000;
  font-size: 20px;
  font-weight: bolder;
  text-decoration: none;
  /* background-color: rgb(75, 211, 209); */
  transition: opacity 2s linear;
  animation: 1s ease-out 0s 1 slideInFromLeft;
}

a {
  text-decoration: none;
}

.picWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: 1s ease-out 0s 1 slideInFromLeft;
}

.profilePicture img {
  height: 250px;
  width: 250px;
  /* border: 1px solid lightblue; */
  border-radius: 200px;
  margin-top: 5px;
}

@media only screen and (min-width: 768px) {
  .aboutContainer {
    display: flex;
  }

  .picWrapper {
    width: 40%;
  }

  .socialMediaButtonGroup {
    width: 100%;
  }

  .socialMediaButton {
    margin-left: 20%;
    width: 50%;
    cursor: pointer;
  }

  .rightSideDesktopView {
    width: 60%;
  }
}