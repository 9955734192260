@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.workContainer {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  animation: 1s ease-out 0s 1 slideInFromRight; 
  /* backdrop-filter: blur(2px) brightness(0.5); */
}

.workContainer img {
  height: 24px;
  width: 10%;
  cursor: pointer;
}

.videoContainer {
  width: 80%;
  height: 100%;
}

.videoBox {
  width: 90%;
  margin-left: 5%;
  height: 30%;
  margin-bottom: 4%;
  animation: 1s ease-out 0s 1 slideInFromRight;
  /* border: 1px solid white; */
}

@media only screen and (min-width: 768px) {
  .workContainer {
    margin: 0 35%;
  }
}
